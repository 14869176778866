<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="投放名稱" prop="nickname" >
        <a-input v-model="form.nickname" placeholder="请输入投放名稱(视频底部作者)" />
      </a-form-model-item>
      <a-form-model-item label="頭像" prop="avatar" >
          <a-upload
            listType="picture-card"
            class="avatar-uploader"
            :showUploadList="false"
            :customRequest='fileUploadRequest'
            :beforeUpload="beforeUpload"
            @change="handleChange"
          >
          <img v-if="form.avatar" :src="form.avatar" alt="圖片" style="height:104px;max-width:300px"/>
            <div v-else>
              <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
              <div class="ant-upload-text">上傳</div>
            </div>
          </a-upload>
          <p style="color:#ff0000">图片建议宽高比：94*94 </p>
          <span style="color:#ff0000">圖片限制小於5M</span>
      </a-form-model-item>
      <a-form-model-item label="視頻資訊" prop="videoUrl" >
          <a-upload
            listType="picture-card"
            class="avatar-uploader"
            :showUploadList="false"
            :customRequest='fileUploadRequestVideo'
            :beforeUpload="beforeUploadVideo"
            @change="handleChangeVideo"
          >
          <video style="width: 100%" v-if="form.videoUrl" :src="form.videoUrl" ref="myVideo"
               :poster=" form.videoUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"/>

            <div v-else>
              <a-icon :type="uploadLoadingVideo ? 'loading' : 'plus'"/>
              <div class="ant-upload-text">上傳</div>
            </div>
          </a-upload>
          <span style="color:#ff0000">視頻限制小於50M</span>
      </a-form-model-item>
      <a-form-model-item label="充值金額(小数点保留2位)" prop="recharge" >
        <a-input v-model="form.recharge" placeholder="请输入充值金額" onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''" />
      </a-form-model-item>
      <a-form-model-item label="展示壹次價格(小数点保留4位)" prop="exposurePrice" >
        <a-input v-model="form.exposurePrice" placeholder="请输入展示壹次價格(如用户浏览一次0.001元)" onkeyup="this.value= this.value.match(/\d+(\.\d{0,4})?/) ? this.value.match(/\d+(\.\d{0,4})?/)[0] : ''" />
      </a-form-model-item>
      <a-form-model-item label="點擊壹次價格(小数点保留4位)" prop="clickPrice" >
        <a-input v-model="form.clickPrice" placeholder="请输入點擊壹次價格(如用户点击一次0.1元)" onkeyup="this.value= this.value.match(/\d+(\.\d{0,4})?/) ? this.value.match(/\d+(\.\d{0,4})?/)[0] : ''" />
      </a-form-model-item>
      <a-form-model-item label="投放内容" prop="content" >
        <a-input v-model="form.content" placeholder="请输入投放内容" type="textarea" allow-clear />
      </a-form-model-item>
      <!-- <a-form-model-item label="视频ID" prop="videoId" >
        <a-input v-model="form.videoId" placeholder="请输入视频ID" />
      </a-form-model-item> -->
      <!-- <a-form-model-item label="接收用戶標簽" prop="labels" >
        <a-input v-model="form.labels" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>-->
      <a-form-model-item label="接收用戶標簽" prop="labels">
        <a-select
          mode="multiple"
          v-model="form.labelList"
          placeholder="請選擇"
        >
          <a-select-option v-for="(d, index) in labelOptions" :key="index" :value="d.id">
            {{ d.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="接收用戶性別" prop="sex" >
        <a-radio-group v-model="form.sex" button-style="solid">
          <a-radio-button :value="1">男</a-radio-button>
          <a-radio-button :value="2">女</a-radio-button>
          <a-radio-button :value="0">全部</a-radio-button>
          <!-- <a-radio-button v-for="(d, index) in sexOptions" :key="index" :value="parseInt(d.value)">{{ d.label }}</a-radio-button> -->
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="接收用戶年齡區間" prop="age" >
        <a-input v-model="form.age" placeholder="请输入接收用户最小年齡" onkeyup = "value=value.replace(/[^\d]/g,'')" />
        <a-input v-model="form.maxAge" placeholder="请输入接收用戶最大年齡" onkeyup = "value=value.replace(/[^\d]/g,'')" />
      </a-form-model-item>
      <a-form-model-item label="接收用戶點贊數(大於等於)" prop="praiseNum" >
        <a-input v-model="form.praiseNum" placeholder="请输入接收用戶點贊數" onkeyup = "value=value.replace(/[^\d]/g,'')" />
      </a-form-model-item>
      <a-form-model-item label="接收用戶收藏數量(大於等於)" prop="collectionNum" >
        <a-input v-model="form.collectionNum" placeholder="请输入接收用戶收藏數量" onkeyup = "value=value.replace(/[^\d]/g,'')" />
      </a-form-model-item>
      <a-form-model-item label="接收用戶分享數量(大於等於) " prop="shareNum" >
        <a-input v-model="form.shareNum" placeholder="请输入接收用戶分享數量 " onkeyup = "value=value.replace(/[^\d]/g,'')"/>
      </a-form-model-item>
      <a-form-model-item label="接收用戶評論數量(大於等於)" prop="discussNum" >
        <a-input v-model="form.discussNum" placeholder="请输入接收用戶評論數量" onkeyup = "value=value.replace(/[^\d]/g,'')"/>
      </a-form-model-item>
      <a-form-model-item label="投放時間段" prop="putInTimes" >
        <!-- <a-input v-model="form.putInTime" placeholder="请输入投放時間段(7-10,10-13)" /> -->
        <a-checkbox-group v-model="form.putInTimes" @change="changePutTime($event,1)">
        <a-row :gutter="24">
          <a-col :span="4" v-for="i in 24" :key="i-1">
            <a-checkbox :value="i-1">
              <span>{{ i-1 }}:00</span>
            </a-checkbox>
          </a-col>
          <!-- <a-col :span="5" v-for="i in 24" :key="i-1">
            <a-checkbox :value="i-1">
              <span v-if="i-1 >=12">{{ i-12 }}:00 pm</span>
              <span v-else>{{ i }}:00 am</span>

            </a-checkbox>
          </a-col> -->
        </a-row>
      </a-checkbox-group>
      </a-form-model-item>
      <a-form-model-item label="同壹個時間段同壹個用戶可以接收幾次" prop="receiveCount" >
        <a-input v-model="form.receiveCount" placeholder="请输入同壹個時間段同壹個用戶可以接收幾次" onkeyup = "value=value.replace(/[^\d]/g,'')"/>
      </a-form-model-item>
       <a-form-model-item label="合約編號" prop="contractNo" >
        <a-input v-model="form.contractNo" placeholder="请输入合約編號" />
      </a-form-model-item>
      <!-- <a-form-model-item label="连接点击次数" prop="clickCount" >
         <a-input v-model="form.clickCount" placeholder="请输入连接点击次数" />
       </a-form-model-item> -->
      <a-form-model-item label="備註" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入備註" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="链接url" prop="linkUrl" >
        <a-input v-model="form.linkUrl" placeholder="请输入鏈接url" />
      </a-form-model-item>
      <a-form-model-item label="鏈接名稱" prop="linkName" >
        <a-input v-model="form.linkName" placeholder="请输入鏈接名稱" />
      </a-form-model-item>
      <a-form-model-item label="鏈接類型" prop="linkTypeId" >
        <!-- <a-input v-model="form.linkTypeId" placeholder="请输入鏈接類型id" /> -->
        <a-select placeholder="请选择" v-model="form.linkTypeId" style="width: 100%" allow-clear>
          <a-select-option v-for="(item, index) in typelList" :key='item.id' :value='item.id'>
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getAdv, addAdv, updateAdv } from '@/api/platform/adv'
import { listTypeLabel} from '@/api/platform/typeLabel'
import UploadFileToOSS from "@/utils/upload-file-to-oss";
import Editor from '@/components/Editor'
import {listType } from '@/api/platform/linktype'

export default {
  name: 'CreateForm',
  props: {
    // sexOptions: {
    //   type: Array,
    //   required: true
    // }
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      uploadLoading: false,
      uploadLoadingVideo: false,
      formTitle: '',
      typelList: [],
      // 表单参数
      form: {
        id: null,

        nickname: null,

        avatar: null,

        balance: null,

        recharge: null,

        clickPrice: null,

        exposurePrice: null,

        content: null,

        videoId: null,

        labels: undefined,

        labelList: [],

        sex: 0,

        age: 0,

        praiseNum: 0,

        collectionNum: 0,

        shareNum: 0,

        discussNum: 0,

        putInTime: null,

        putInTimes: [],

        receiveCount: 2,

        exposureCount: null,

        clickCount: null,

        createTime: null,

        remark: null,

        linkUrl: null,

        linkName: null,

        linkTypeId: null,

        videoUrl: null,
        maxAge: 0,
        contractNo:null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      labelOptions: [],
      rules: {
        nickname: [
          { required: true, message: '投放名稱不能为空', trigger: 'blur' }
        ],
        avatar: [
          { required: false, message: '頭像不能为空', trigger: 'blur' }
        ],
        recharge: [
          { required: true, message: '充值金額不能为空', trigger: 'blur' }
        ],
        exposurePrice: [
          { required: true, message: '展示壹次價格不能为空', trigger: 'blur' }
        ],
        clickPrice: [
          { required: true, message: '點擊壹次價格不能为空', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '投放內容不能为空', trigger: 'blur' }
        ],
        contractNo: [
          { required: true, message: '合約編號不能为空', trigger: 'blur' }
        ],
        sex: [
          { required: true, message: '接收用戶性別不能为空', trigger: 'blur' }
        ],
        age: [
          { required: true, message: '接收用戶年齡不能为空', trigger: 'blur' }
        ],
        maxAge: [
          { required: true, message: '接收用戶年齡不能为空', trigger: 'blur' }
        ],
        praiseNum: [
          { required: true, message: '接收用戶點贊數不能为空', trigger: 'blur' }
        ],
        collectionNum: [
          { required: true, message: '接收用戶收藏數量不能为空', trigger: 'blur' }
        ],
        shareNum: [
          { required: true, message: '接收用戶分享數量不能为空', trigger: 'blur' }
        ],
        discussNum: [
          { required: true, message: '接收用戶評論數量不能为空', trigger: 'blur' }
        ],
        putInTimes: [
          { required: true, message: '投放時間段不能为空', trigger: 'blur' }
        ],
        receiveCount: [
          { required: true, message: '同壹個時間段同壹個用戶可以接收幾次不能为空', trigger: 'blur' }
        ],
      }
    }
  },
  filters: {
  },
  created () {
    this.getLabelList()
    this.getTypeList()
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    getTypeList () {
      listType(this.queryParam).then(response => {
        this.typelList = response.data
      })
    },
    getLabelList(){
      listTypeLabel().then(response => {
        this.labelOptions = response.data
        this.loading = false
      })
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        nickname: null,
        avatar: null,
        balance: null,
        recharge: null,
        clickPrice: null,
        exposurePrice: null,
        content: null,
        videoId: null,
        labels: undefined,
        labelList: [],
        sex: 0,
        age: 0,
        praiseNum: 0,
        collectionNum: 0,
        shareNum: 0,
        discussNum: 0,
        putInTime: null,
        putInTimes: [],
        receiveCount: 2,
        exposureCount: null,
        clickCount: null,
        createTime: null,
        remark: null,
        linkUrl: null,
        linkName: null,
        linkTypeId: null,
        videoUrl: null,
        maxAge: 0,
        contractNo:null
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getAdv({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
        this.getLabelList();
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          let age = this.form.age;
          let maxAge = this.form.maxAge;
          if(maxAge<=age){
            this.$message.warning(
              '最大年齡不能小於最小年齡',
              3
            )
            return;

          }
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateAdv(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addAdv(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    fileUploadRequest(fileInfo) {
      this.uploadLoading = true
      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          console.log("res========>"+JSON.stringify(res))
          // Log.info('multi-img-upload', res);
          const ossUrl = res[0].url;
          fileInfo.file.ossUrl = ossUrl;
          fileInfo.file.ossName = ossUrl;
          fileInfo.onSuccess();
        },
      });
    },
    beforeUpload: function (file) {
      return new Promise((resolve, reject) => {
        if(file.size>5*1024*1000){
          this.$message.warning("圖片大小不能超過5M----")
          return reject(false)
        }

        var fileType = file.type;
        if (fileType.indexOf('image') < 0) {
          this.$message.warning('請上傳圖片');
          return reject(false);
        }
        resolve(true)
      });
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoading = true
        return
      }
      if (info.file.status === 'done') {
        // let response = info.file.response;
        this.uploadLoading = false;
        this.form.avatar = info.file.originFileObj.ossUrl;
      }
    },
    fileUploadRequestVideo(fileInfo) {
      this.uploadLoadingVideo = true
      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          console.log("res========>"+JSON.stringify(res))
          // Log.info('multi-img-upload', res);
          const ossUrl = res[0].url;
          fileInfo.file.ossUrl = ossUrl;
          fileInfo.file.ossName = ossUrl;
          fileInfo.onSuccess();
        },
      });
    },
    beforeUploadVideo: function (file) {
      return new Promise((resolve, reject) => {
        if(file.size>50*1024*1000){
          this.$message.warning("視頻大小不能超過50M----")
          return reject(false)
        }

        var fileType = file.type;
        if (fileType.indexOf('mp4') < 0) {
          this.$message.warning('請上傳視頻');
          return reject(false);
        }
        resolve(true)
      });
    },
    handleChangeVideo(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoadingVideo = true
        return
      }
      if (info.file.status === 'done') {
        // let response = info.file.response;
        this.uploadLoadingVideo = false;
        this.form.videoUrl = info.file.originFileObj.ossUrl;
      }
    },
    changePutTime(event,value){
      // this.form.putInTime = this.checkedList;
    }
  }
}
</script>
